<template>
<div class="grid-page-wrapper">
    <h1 class="display-4">Work orders dashboard</h1>
    <hr>
    <div class="d-grid gap-2 col-6 mx-auto">
        <router-link :to="{ name: 'backoffice-dashboard-orders-all' }" class="btn btn-primary">
            All work orders
        </router-link>
        <router-link :to="{ name: 'backoffice-dashboard-orders-finished' }" class="btn btn-primary">
            Finished work orders
        </router-link>
    </div>
</div>
</template>

<script>
export default {
    setup() {
        return {
           
        }
    }
}
</script>